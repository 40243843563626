@import url("https://fonts.googleapis.com/css?family=Spectral|Caudex|Roboto+Condensed:400&display=swap");

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Spectral', 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-attachment: fixed;
    background-position: center;
  }

  .navbar-brand {
    font-size: 1rem;
    font-weight: bolder;
  }
  
.desc {
    font-style: italic;
    font-size: 0.8em;
}

.date {
    font-style: italic;
    font-size: 0.8em;
}

.badge-lg {
    font-size: 1.1em;
}

.cb {
    font-size: 0.8em;
    font-style: italic;
    font-weight: bold;
}

.loader {
    min-height: calc(100vh - 45px);
    padding-top: calc(50vh - 45px);
}

.app-bottom-bar {
    li.nav-item {
        list-style: none;
        min-width: 20%;
        text-align: center;
    }
}

.login-form {
    margin-top: calc(50vh - 220px);
}

.btn-setup {
    color: #fff !important;
}

.accordion-button:not(.collapsed) {
    color: #fff;
}

.back-to-home {
    position: fixed;
    bottom: 60px;
    left: 20px;
    width: 40px;
    border-radius: 50px;
    text-align: center;
}

.page-header {
    color: #fff;
    padding: 1rem;
    padding-bottom: 100px;
    border-radius: 10px;

    h1, h2, h3 {
        font-weight: bolder;
    }
}

.page-items {
    margin: -50px 1rem 0;
}

.card-item {
    margin: 5px 0;
    border-width: 1px !important;
    border-radius: 6px;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    -webkit-box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
    box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);

    h1, h2, h3 {
        font-weight: bolder;
        margin-bottom: 0;
    }
}

.version-number {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 0.9rem;
}

.page-dashboard {
    a {
        color: #000;
        text-decoration: none;
    }

    .card {
        background-color: #fff !important;
        min-height: 200px;

        .cta {
            position: absolute;
            bottom: 15px;
            left: 15px;
            min-width: 150px;
        }
    }

    h5 {
        font-weight: bolder;
    }
}

.footer {
    color: #fff;
    margin-left: -0.75rem;
    margin-right: -0.75rem;

    a {
        color: #fff;
        text-decoration: none; 
    }
}

.bg-image {    
    margin-left: -0.75rem;
    margin-right: -0.75rem;
    margin-top: -10px;
}

.page {
    background-color: #fff;

    .btn-back-to-home {
        position: fixed;
        bottom: 60px;
        right: 20px;
        text-align: center;
        color: #fff;
    }
}

.page-payment-request {
    .card h5 {
        font-weight: bolder;
    }

    .card-body {
        padding: 0.5rem;
    }

    .card-header {
        padding: 0.2rem;
    }
}
